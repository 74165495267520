import Helmet from 'react-helmet';
import React from 'react';
import { siteTitle, siteDescription } from '../site-config';

export default function FourOhFour() {
  return (
    <div>
      <Helmet title={siteTitle} meta={[{ name: 'description', content: siteDescription }]} />
      404!
    </div>
  );
}
